import React, { Component, createRef } from "react";
import { LOGOS } from "../../constants";
import "./styles/logos.css";
import "./styles/home.css";
import InviteCodeAPI from "../../api/invite-code";
import Background from "../../video/ink.mp4"

/**
 * Home Page
 */
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeToCode: false,
      hideHeader: false,
      showInput: false,
      inviteCode: "",
      errorText: "",
    };
    this.enter = createRef();
    this.onCodeSubmit = this.onCodeSubmit.bind(this);
    this.logo = LOGOS[Math.floor(Math.random() * LOGOS.length)];
  }

  /**
   * Component mount
   */
  componentDidMount() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  }

  /**
   * Handles the invite code onSubmit
   * @param {Object} e
   */
  async onCodeSubmit(e) {
    e.preventDefault();
    this.enter.current.innerHTML = "";
    this.enter.current.className = "loader";
    InviteCodeAPI.validateCode(this.state.inviteCode, (err, data) => {
      if (err) {
        this.enter.current.innerHTML = "Enter";
        this.enter.current.className = "";
        this.setState({ errorText: err.error });
      } else window.location.href = "/dashboard/mscc";
    });
  }

  render() {
    // Content div properties to be spread
    const content_container = {
      className: "content-container",
    };
    // div content to display code field to be spread
    const code_input_container = {
      className: "code-input-container open",
    };
    // input for invite code to be spread
    const code_input = {
      placeholder: "Invite Code",
      onChange: ({ target: { value } }) =>
        this.setState({ inviteCode: value.toUpperCase(), errorText: "" }),
      value: this.state.inviteCode,
      type: "text",
    };
    // input for invite code to be spread
    const error_text = {
      className:
        this.state.errorText === ""
          ? "invite-code-error"
          : "invite-code-error open",
    };

    return (
      <div>
        <div className="main-bkgd">
          <video autoPlay muted loop playsInline>
            <source src={Background} type="video/mp4" />
          </video>
          <div className="home-content">
            <div className={`logo-header ${this.logo}`} />
            <div {...content_container}>
              <div {...code_input_container}>
                <h1 className="invitation">Invitation-Only</h1>
                <form onSubmit={this.onCodeSubmit} className="invite-code-form">
                  <input {...code_input} />
                  <button type="submit">
                    <div ref={this.enter}>Enter</div>
                  </button>
                </form>
                <p {...error_text}>{this.state.errorText}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
