import axios from "axios";
import SecureLS from "secure-ls";
export const ls = new SecureLS({ encodingType: "aes" });

/**
 * Interacts witht the api for invite codes
 */
export default class InviteCodeAPI {
  /**
   * Validates an invite code and loggs into the api/gets JWT tokens
   * @param {String} code
   * @param {Function} cb (err, data)
   */
  static async validateCode(code, cb) {
    try {
      const validation = await axios.post(
        `/api/invitation/validate?code=${code}`
      );
      if (!validation.data.error) {
        ls.set("74", validation.data.token);
        ls.set("7274", validation.data.refreshToken);
        cb(null, validation.data);
      } else cb({ error: validation.data.error }, null);
    } catch (err) {
      cb({ error: err.response ? err.response.data.error : err.message }, null);
    }
  }

  /**
   * Updates the invite code attributes.
   * @param {Object} body items to update
   * @param {Function} cb callback function (err, updated_invite_code)
   */
  static async updateInviteCode(body, cb) {
    try {
      const savedToken = ls.get("74");
      if (savedToken) {
        try {
          const update = await axios.patch(
            "/api/invitation",
            JSON.stringify({ ...body }),
            { headers: { Authorization: `Bearer ${savedToken}` } }
          );
          cb(null, update.data);
        } catch (err) {
          if (err.response) {
            const savedRefreshToken = ls.get("7274");
            if (savedRefreshToken) {
              try {
                const newTokens = await axios.post(
                  "/api/invitation/revalidate",
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${savedRefreshToken}`,
                    },
                  }
                );
                ls.set("74", newTokens.data.token);
                ls.set("7274", newTokens.data.refreshToken);
                const update = await axios.patch(
                  "/api/invitation",
                  JSON.stringify({ ...body }),
                  { headers: { Authorization: `Bearer ${savedToken}` } }
                );
                cb(null, update.data);
              } catch (err2) {
                cb(
                  {
                    error: err2.response
                      ? err2.response.data.error
                      : err2.message,
                  },
                  null
                );
              }
            }
            cb({ error: "No token" }, null);
          }
          cb(
            { error: err.response ? err.response.data.error : err.message },
            null
          );
        }
      } else cb({ error: "No token" }, null);
    } catch (err) {
      cb({ error: err.response ? err.response.data.error : err.message }, null);
    }
  }
}
