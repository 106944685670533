import React from "react";
import Car from "../../video/car.mp4";
import "./styles/contact.css";

const ContactUs = () => {
  return (
    <div className="main-contact-container">
      <section className="contacts-header">
        <video autoPlay muted loop playsInline>
          <source src={Car} type="video/mp4" />
        </video>
        <h1>Contact Us</h1>
        <div className="skewed3-u" />
      </section>
      <section>
        <div className="skewed2" />
        <div className="contact-bar">
          <div className="contact">
            <h1>Brecht Cobaldt</h1>
            <div className="brecht-headshot" />
            <h3>Owner - Founder</h3>
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia, molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum numquam blanditiis harum quisquam eius sed odit
              fugiat iusto fuga praesentium optio, eaque rerum!
            </p> */}
            <div className="contect-info-links">
              <a href="tel:15617898958">+1-561-789-8958</a>
              <a href="mailto:inquiries@projectmonoceros.com">
                inquiries@projectmonoceros.com
              </a>
            </div>
          </div>
          <div className="contact">
            <h1>Mike Swing</h1>
            <div className="mike-headshot" />
            <h3>Owner - Founder</h3>
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia, molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum numquam blanditiis harum quisquam eius sed odit
              fugiat iusto fuga praesentium optio, eaque rerum!
            </p> */}
            <div className="contect-info-links">
              <a href="tel:13032691093">+1-303-269-1093</a>
              <a href="mailto:inquiries@projectmonoceros.com">
                inquiries@projectmonoceros.com
              </a>
            </div>
          </div>
        </div>
        <div className="skewed3" />
      </section>
    </div>
  );
};

export default ContactUs;
