import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../api/auth";
import "./pages/styles/protected-route.css";

export const ProtectedRoute = ({
  component: Component,
  dash_check,
  ...rest
}) => {
  const [code, setCode] = useState(null);

  useEffect(() => {
    var mounted = true;
    if (mounted) {
      Auth.getInviteCode().then((c) => {
        if (c) setCode(c);
        else setCode({ error: "Not authorized" });
      });
    }
    return () => (mounted = false);
  }, []);

  if (code === null)
    return (
      <div className="pr-loading-container">
        <div className="pr-loading-logo logo-11" />
        <p>Loading . . .</p>
      </div>
    );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!code.error && dash_check)
          return (
            <Redirect
              to={{
                pathname: "/dashboard/mscc",
                state: { from: props.location },
              }}
            />
          );
        if (code.error && dash_check) return <Component {...rest} {...props} />;
        if (!code.error && !dash_check)
          return <Component {...rest} {...props} code={code} />;
        return (
          <Redirect
            to={{
              pathname: "/not-authorized",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
