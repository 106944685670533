import React from "react";
import Speedometer from "../../video/speedometer.mp4";
import "./styles/update-logs.css";

const UpdateLogs = () => {
  return (
    <div className="main-update-container">
      <section className="updates-header">
        <video autoPlay muted loop playsInline>
          <source src={Speedometer} type="video/mp4" />
        </video>
        <h1>Update Log</h1>
        <div className="skewed3-u" />
      </section>
      <section>
        <div className="skewed2" />
        <div className="logs-content-container">
          <div
            className="home-btn"
            onClick={() => (window.location.href = "/dashboard/mscc")}
          >
            <span>Back to Home</span>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  <strong>Date</strong>
                </th>
                <th>
                  <strong>Updates</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* 1 */}
              <tr>
                <td>07/24/2021</td>
                <td>
                  <ul>
                    <li>Spelling and grammar fixes</li>
                    <li>Updated By-Laws Document</li>
                  </ul>
                </td>
              </tr>
              {/* 2 */}
              <tr>
                <td>08/19/2021</td>
                <td>
                  <ul>
                    <li>Updated Project Overview Document</li>
                    <li>Updated By-Laws Document</li>
                    <li>Updated Application Document</li>
                  </ul>
                </td>
              </tr>
              {/* 3 */}
              <tr>
                <td>03/03/2022</td>
                <td>
                  <ul>
                    <li>Updated Project Overview Document</li>
                    <li>Updated By-Laws Document</li>
                    <li>Updated Application Document</li>
                    <li>Updated login smoke animation</li>
                    <li>Edited home page entry header</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="skewed3" />
      </section>
    </div>
  );
};

export default UpdateLogs;
