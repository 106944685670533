import React, { useState, useEffect, Fragment } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import {
  FiDownload,
  FiArrowUp,
  FiArrowLeft,
  FiArrowRight,
} from "react-icons/fi";
import { isMobile } from "react-device-detect";
import "./styles/document.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PageLoader = () => {
  return (
    <div className="page-loader-container">
      <div className="page-loader" />
      <h1>Loading PDF...</h1>
    </div>
  );
};

const PDFLoadFail = () => {
  return (
    <div className="page-loader-container">
      <h1>
        Failed to load PDF :(
        <br />
        <br />
        Please try again later or refresh the page.
      </h1>
      <br />
      <p>If the problem persists, open the PDF in a new tab or download it.</p>
    </div>
  );
};

const DocumentPage = ({ doc }) => {
  const [numPages, setNumPages] = useState(null);
  const [title, setTitle] = useState("PDF");
  const [file, setFile] = useState(null);
  const [inputPage, setInputPage] = useState(1);
  const [last, setLast] = useState({ title: "", link: "" });
  const [next, setNext] = useState({ title: "", link: "" });

  useEffect(() => {
    var mounted = true;
    if (mounted)
      switch (doc) {
        case "project-overview":
          setFile(
            `${window.location.origin}/cdn/files/Motorsports-Country-Club-Project.pdf`
          );
          setTitle("Motorsports Country Club Overview");
          setLast({
            title: "Application",
            link: "/dashboard/document?file=application",
          });
          setNext({
            title: "By-Laws",
            link: "/dashboard/document?file=by-laws",
          });
          break;
        case "by-laws":
          setFile(
            `${window.location.origin}/cdn/files/Motorsports-Country-Club-by-laws.pdf`
          );
          setTitle("Motorsports Country Club By-Laws");
          setLast({
            title: "Overview",
            link: "/dashboard/document?file=project-overview",
          });
          setNext({
            title: "Application",
            link: "/dashboard/document?file=application",
          });
          break;
        case "application":
          setFile(
            `${window.location.origin}/cdn/files/Motorsports-Country-Club-PreLaunch-Member-Application.pdf`
          );
          setTitle("Motorsports Country Club Pre-Launch Member Application");
          setLast({
            title: "By-Laws",
            link: "/dashboard/document?file=by-laws",
          });
          setNext({
            title: "Overview",
            link: "/dashboard/document?file=project-overview",
          });
          break;
        default:
          break;
      }
    return () => (mounted = false);
  }, [doc]);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const downloadFile = () => {
    fetch(file, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const onPageChange = (event) => setInputPage(event.target.value);

  const jumpToPage = (e) => {
    e.preventDefault();
    document
      .querySelector(`#page${inputPage}`)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div id="top" className="doc-main-container">
      <div className="header-title">
        <h1>{title}</h1>
      </div>
      <div className="header-btn-bar">
        <div className="header-btn" onClick={() => window.history.back()}>
          Back
        </div>
        <div className="header-btn" onClick={() => window.open(file, "_blank")}>
          Open in New Tab
        </div>
      </div>
      <div>
        <div className="pdf-nav-bar">
          <span className="page-span">
            <form onSubmit={jumpToPage}>
              Page&nbsp;&nbsp;
              <input
                className="page-input"
                value={inputPage}
                type="number"
                onChange={onPageChange}
                max={numPages}
                min={1}
              />
              &nbsp;/&nbsp;{numPages}
            </form>
          </span>
          <span className="pipe-span">|</span>
          <span className="download-span" onClick={downloadFile}>
            <FiDownload size={20} />
            &nbsp; Download
          </span>
        </div>
        <pre className="document-main-pre">
          {new Array(numPages).fill(<Fragment />, 0, numPages).map((p, i) => (
            <div key={i} className="doc-page" id={`page${i + 1}`}>
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={PageLoader}
                options={{
                  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                }}
                error={PDFLoadFail}
              >
                <Page
                  pageNumber={i + 1}
                  width={
                    isMobile
                      ? window.innerWidth - 50
                      : (window.innerWidth / 3) * 2
                  }
                />
              </Document>
            </div>
          ))}
        </pre>
      </div>
      <div className="button-footer-bar">
        <div
          className="header-btn"
          onClick={() => (window.location.href = last.link)}
        >
          <span className="top-span">
            <FiArrowLeft size={20} />
            &nbsp; {last.title}
          </span>
        </div>
        <div
          className="header-btn"
          onClick={() =>
            document
              .querySelector("#top")
              .scrollIntoView({ behavior: "smooth", block: "start" })
          }
        >
          <span className="top-span">
            <FiArrowUp size={20} />
            &nbsp; To Top
          </span>
        </div>
        <div
          className="header-btn"
          onClick={() => (window.location.href = next.link)}
        >
          <span className="top-span">
            {next.title} &nbsp;
            <FiArrowRight size={20} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DocumentPage;
