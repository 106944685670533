import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";

// Main Pages
import Home from "./components/pages/Home";
// Protected Pages
import Dashboard from "./components/pages/Dashboard";

// DEV Options
import { LOGIN_SCREEN } from "./constants";
import SecureLS from "secure-ls";
import axios from "axios";
/**
 * Encoded local storage store
 */
const ls = new SecureLS({ encodingType: "aes" });

function App() {
  // state for login splash screen
  const init_state = {
    username: "",
    password: "",
  };
  const [state, setState] = useState({ ...init_state });
  const { password, username } = state;

  /**
   * Handles the login functionality from the splash screen
   * @param {Object} e
   */
  const submitLogin = async (e) => {
    e.preventDefault();
    try {
      const login = await axios.post("/api/site-login", JSON.stringify(state), {
        headers: { "Content-Type": "application/json" },
      });
      if (login.data.code === 200) {
        ls.set("ae1b4f", Date.now() + 86400000);
        window.location.reload();
      }
    } catch {
      setState({ ...init_state });
      alert("Invalid Login");
    }
  };

  /**
   * Updates state vars with input values
   * @param {Object} param0 // the changing object (input)
   */
  const onChange = ({ target: { value, name } }) =>
    setState((s) => ({ ...s, [name]: value }));

  // Login screen show or passover logic
  if (LOGIN_SCREEN) {
    if (!ls.get("ae1b4f") || new Date(ls.get("ae1b4f")) < Date.now())
      return (
        <div className="login-splash-main">
          <form onSubmit={submitLogin} id="login-form">
            <h1>Login:</h1>
            <input
              className="login-input"
              name="username"
              type="text"
              placeholder="username"
              autoComplete="username"
              onChange={onChange}
              value={username}
            />
            <input
              className="login-input"
              name="password"
              type="password"
              placeholder="password"
              autoComplete="current-password"
              onChange={onChange}
              value={password}
            />
            <button className="login-input" type="submit">
              Login
            </button>
          </form>
        </div>
      );
  }

  return (
    <Router>
      <Switch>
        <ProtectedRoute dash_check={true} exact path="/" component={Home} />
        <ProtectedRoute
          dash_check={false}
          exact
          path="/dashboard/:page"
          component={Dashboard}
        />
        <Route exact path="/not-authorized">
          <div className="error">
            <h1>401/403 Not Authorized/Forbidden</h1>
            <p>You do not have access to view this page.</p>
            <a className="error-btn" href="/">
              Home
            </a>
          </div>
        </Route>
        <Route exact path="*">
          <div className="error">
            <h1>404 Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <a className="error-btn" href="/">
              Home
            </a>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
