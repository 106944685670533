import React, { Fragment } from "react";
import { FiArrowRight } from "react-icons/fi";
import Rally from "../../video/rally_car.mp4";
import "./styles/motorsports-project.css";

const MotorsportCountryClubProject = ({ code }) => {
  const onPDFClick = (i) => {
    switch (i) {
      case 1: // Project Overview
        window.location.href = "/dashboard/document?file=project-overview";
        break;
      case 2: // By-Laws
        window.location.href = "/dashboard/document?file=by-laws";
        break;
      case 3: // Application
        window.location.href = "/dashboard/document?file=application";
        break;
      default:
        alert("An unexpected error occurred.");
        break;
    }
  };

  return (
    <Fragment>
      <section className="sec1">
        <div>
          <div className="header-content">
            <section className="header-video-container">
              <div className="future-motion">
                <h1 className="future">The Future Is In Motion</h1>
              </div>
              <div className="skewed1" />
            </section>
            <div className="header-headlies-container">
              <section className="header-headline-row">
                <div className="skewed2-u" />
                <section className="header-headline">
                  <h1>
                    Welcome to the exclusive launch of the ultimate motorsports
                    country club experience.
                  </h1>
                  <h1 style={{ marginBottom: "40px" }}>
                    Memberships now available for serious motorsports
                    enthusiasts.
                  </h1>
                  <div className="tri-bar-container">
                    <div className="green" />
                    <div className="white" />
                    <div className="red" />
                  </div>
                  <div className="pdf-button" onClick={() => onPDFClick(1)}>
                    <span>
                      View More Details&nbsp;&nbsp;
                      <FiArrowRight size={20} />
                    </span>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
        <div className="skewed1" />
      </section>
      <section id="application" className="sec2 center">
        <div className="skewed2-u" />
        <div className="row">
          <div className="col img-3" />
          <div className="col">
            <h1>Membership</h1>
            <p>
              This world-class experience is now open to founding members. To
              apply, complete the membership form below and get in touch with
              either Brecht or Mike for an interview.
            </p>
            <div className="pdf-button" onClick={() => onPDFClick(3)}>
              <span>
                View Application&nbsp;&nbsp;
                <FiArrowRight size={20} />
              </span>
            </div>
          </div>
        </div>

        <div className="skewed3-u" />
      </section>
      <section id="overview" className="sec3">
        <div className="skewed2" />
        <div className="row project-overview">
          <div className="col">
            <h1>Project Overview</h1>
            <p>
              We invite you to take a more in depth look at our Motorsports
              Country Club Project.
            </p>
            <div className="pdf-button inverse" onClick={() => onPDFClick(1)}>
              <span>
                View More Details&nbsp;&nbsp;
                <FiArrowRight size={20} />
              </span>
            </div>
          </div>
          <div className="col2">
            <video autoPlay muted loop playsInline>
              <source src={Rally} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="skewed3" />
      </section>
      <section id="by-laws" className="sec2 center">
        <div className="skewed2-u" />
        <div className="row">
          <div className="col img-2" />
          <div className="col">
            <h1>By-Laws</h1>
            <p>We encurage curious applicants to take a peek at our by-laws.</p>
            <div className="pdf-button" onClick={() => onPDFClick(2)}>
              <span>
                View By-Laws&nbsp;&nbsp;
                <FiArrowRight size={20} />
              </span>
            </div>
          </div>
        </div>
        <div className="skewed3-u" />
      </section>
      <section className="sec3">
        <div className="skewed2" />
      </section>
    </Fragment>
  );
};

export default MotorsportCountryClubProject;
