import React from "react";
import "./styles/comming-soon.css";

const ComingSoon = ({ code }) => {
  if (code.role !== "ADMIN")
    return (
      <div className="main-projects-container-cs">
        <h1>Coming Soon.&nbsp;.&nbsp;.</h1>
        <div className="gif">
          <iframe
            src="https://giphy.com/embed/11bV8o4fJ3vpOE"
            width="100%"
            height="100%"
            style={{ position: "absolute" }}
            frameBorder="0"
            class="giphy-embed"
            allowFullScreen
            title="Sneaky, Sneaky..."
          ></iframe>
        </div>
        <a
          className="gif-link"
          href="https://giphy.com/gifs/cats-photos-thechive-11bV8o4fJ3vpOE"
          target="_blank"
          rel="noreferrer"
        >
          via GIPHY
        </a>
        <h2>Sneaky, Sneaky...</h2>
        <p>Trying to gether intel on future content?</p>
        <p>
          First, props for finding the "Coming Soon" link. (even if it was
          accidental). In the future, new content will be available to explore.
          <br />
          Check back from time to time and you might find something new and
          exciting within the Club 4321 network.
        </p>
      </div>
    );

  return (
    <p>
      Projects: <br />- Project Monoceros
      <br />- Project Zeda
    </p>
  );
};

export default ComingSoon;
