import React, { Component } from "react";
import MotorsportCountryClubProject from "./MotorsportsProject";
import CommingSoon from "./ComingSoon";
// import Partners from "./Partners";
import ContactUs from "./Contact";
import Document from "./Document";
import UpdateLogs from "./UpdateLogs";
import "./styles/dashboard.css";
import Auth from "../../api/auth";
import { LOGOS } from "../../constants";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { page: null, active: "" };
    this.setPage = this.setPage.bind(this);
    this.formatRole = this.formatRole.bind(this);
    this.logout = this.logout.bind(this);
    this.logo = LOGOS[Math.floor(Math.random() * LOGOS.length)];
    this.params = new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    this.mounted = true;
    this.setPage(this.props.computedMatch.params.page);
    if (document.location.hash && document.location.hash !== "#0") {
      setTimeout(() => {
        document
          .querySelector(document.location.hash)
          .scrollIntoView({ behavior: "smooth", block: "start" });
      }, 25);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  formatRole = () => {
    return this.props.code.role.replace(
      /^(\w)(.+)/,
      (_m, p1, p2) => p1.toUpperCase() + p2.toLowerCase()
    );
  };

  setPage = (page) => {
    if (this.mounted) {
      switch (page) {
        case "mscc":
          this.setState({
            page: <MotorsportCountryClubProject code={this.props.code} />,
            active: page,
          });
          break;
        case "cs":
          this.setState({
            page: <CommingSoon code={this.props.code} />,
            active: page,
          });
          break;
        // case "partners":
        //   this.setState({
        //     page: <Partners />,
        //     active: page,
        //   });
        //   break;
        case "contact":
          this.setState({
            page: <ContactUs />,
            active: page,
          });
          break;
        case "document":
          this.setState({
            page: <Document doc={this.params.get("file")} />,
            active: page,
          });
          break;
        case "logs":
          this.setState({
            page: <UpdateLogs />,
            active: page,
          });
          break;
        default:
          window.location.href = "/dashboard/mscc";
      }
    } else this.props.history.push("/");
  };

  logout = () => {
    Auth.logout(() => {
      window.location.href = "/";
    });
  };

  render() {
    const { active } = this.state;
    const { role } = this.props.code;
    return (
      <div>
        <div>
          <div className="nav-bar">
            <div className={`nav-bar-img ${this.logo}`} />
            <a href="/dashboard/logs" className="last-update-link-container">
              Last Update: 03/03/2022
            </a>
            <div className="spacer nav-spacer" />
            <div className="nav-bar-container">
              <div
                className={`mscc nav-menu-item ${
                  active === "mscc" ? "active" : ""
                }`}
              >
                <div className="dropdown">
                  <a className="main" href="/dashboard/mscc">
                    MSCC
                  </a>
                  <div className="dropdown-content">
                    <a href="/dashboard/mscc#overview">Overview</a>
                    <a href="/dashboard/mscc#by-laws">By-Laws</a>
                    <a href="/dashboard/mscc#application">Application</a>
                  </div>
                </div>
              </div>
              <div
                className={`nav-menu-item ${active === "cs" ? "active" : ""}`}
              >
                <div className="dropdown">
                  <a
                    href={role === "ADMIN" ? "/dashboard/cs" : "#0"}
                    className={role === "ADMIN" ? "main" : "main locked"}
                  >
                    Coming
                    <br />
                    Soon
                  </a>
                  <div className="dropdown-content">
                    <a
                      className={role === "ADMIN" ? "" : "disabled"}
                      href="/dashboard/cs#projectmonoceros"
                    >
                      {role === "ADMIN" ? "Project Monoceros" : "Coming Soon"}
                    </a>
                    <a
                      className={role === "ADMIN" ? "" : "disabled"}
                      href="/dashboard/cs#projectzeda"
                    >
                      {role === "ADMIN" ? "Project Zeda" : "Coming Soon"}
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
                className={`nav-menu-item ${
                  active === "partners" ? "active" : ""
                }`}
              >
                <div className="dropdown">
                  <a href="/dashboard/partners" className="main">
                    Partners
                  </a>
                </div>
              </div> */}
              <div
                className={`nav-menu-item ${
                  active === "contact" ? "active" : ""
                }`}
              >
                <div className="dropdown">
                  <a href="/dashboard/contact" className="main">
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="nav-menu-item" onClick={this.logout}>
                <div className="dropdown">
                  <a href="#0" className="main">
                    Logout
                  </a>
                  <div className="dropdown-content">
                    <a href="#0" className="disabled cntr">
                      Logged in as:
                      <br />
                      {this.formatRole()}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-menu-container"></div>
        </div>
        {this.state.page}
        <footer className="footer">
          <div className="footer-bar-img logo-main" />
          <div className="footer-bar-main-container">
            <div className="footer-contact-bar">
              <div className="brecht">
                <h2>Brecht Cobaldt</h2>
                <a className="footer-phone" href="tel:15617898958">
                  +1-561-789-8958
                </a>
              </div>
              <div className="mike">
                <h2> Mike Swing</h2>
                <a className="footer-phone" href="tel:13032691093">
                  +1-303-269-1093
                </a>
              </div>
            </div>
            <a
              className="footer-email"
              href="mailto:inquiries@projectmonoceros.com"
            >
              inquiries@projectmonoceros.com
            </a>
          </div>
          <div className="spacer" />
          <div className="footer-nav-container">
            <div className="footer-nav">
              <a href="/dashboard/mscc">MSCC</a>
              <a
                href={role === "ADMIN" ? "/dashboard/cs" : "#0"}
                className={role === "ADMIN" ? "" : "locked"}
              >
                Coming Soon
              </a>
              {/* <a href="/dashboard/partners">Partners</a> */}
              <a href="/dashboard/contact">Contact Us</a>
            </div>
            <div className="spacer" />
            <p id="footer-cpyrt">
              © 2022 Crigon Group NA
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;The future is in motion.</span>
            </p>
          </div>
        </footer>
      </div>
    );
  }
}
